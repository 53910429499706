/* eslint-disable-next-line */
import { Fancybox } from '@fancyapps/ui/src/Fancybox/Fancybox';
/* eslint-disable-next-line */
import Swiper, {
    Navigation, Pagination, Autoplay, Controller, EffectFade
} from 'swiper';

/* eslint-disable-next-line */
import { CountUp } from 'countup.js';
/* eslint-disable-next-line */
import Plyr from 'plyr';
import dataStore from './inc/dataStore';

// import { herrlichesMenu, mobileSubmenu, hmNavInit } from './inc/header';
import browserDetection from './inc/browserDetection';
import observeIntersection from './inc/observeIntersection';
import devAt from './inc/devAt';

Swiper.use([Navigation, Pagination, Autoplay, Controller, EffectFade]);

/* eslint-disable-next-line */
const LazyLoad = require('vanilla-lazyload');

window.jQuery = jQuery;
window.$ = jQuery;

/* Author: herrlich media */
$(document).ready(() => {
    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Ready Inits +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* browser/touch detection ++++++++++++++++++++++++++++++++++++++++++*/
    browserDetection();

    contentOffset();

    headerScrolled();

    /* Big Header Observer ++++++++++++++++++++++++++++++++++++++++++*/
    observeIntersection('#bigHeaderWrapper.hasMedia', (e, intersected) => {
        if (intersected) {
            dataStore.header.removeClass('isDark').addClass('onMedia');
        } else {
            dataStore.header.addClass('isDark').removeClass('onMedia');
        }
    }, false, 0, undefined, '-48px 0px 0px 0px');

    /* Full WIdth Img Observer ++++++++++++++++++++++++++++++++++++++++++*/
    observeIntersection('.imagesBlock .wp-block-image.alignfull, .parallaxBackground', (e, intersected) => {
        if (intersected) {
            dataStore.header.removeClass('isDark').addClass('overMedia');
        } else {
            dataStore.header.addClass('isDark').removeClass('overMedia');
        }
    }, false, 0, undefined, '-10% 0% -90% 0%');

    /* Intersection Observer  ++++++++++++++++++++++++++++++++++++++++++*/
    observeIntersection('.fadeIn', (e, intersected) => {
        if (intersected) {
            $(e.target).not('.vis').addClass('vis');

            setTimeout(() => {
                $(e.target).removeClass('fadeIn vis');
            }, 500);
        }
    }, true);

    observeIntersection('.fadeInRow', (e, intersected) => {
        if (intersected) {
            $(e.target)
                .find('> div, > li, > p')
                .not('.vis')
                .each((i, el) => {
                    setTimeout(() => {
                        $(el).addClass('vis');
                    }, 150 * i);
                });
        }
    }, true);

    observeIntersection('.count .number', (e, intersected) => {
        if (intersected) {
            const options = {
                separator: '.',
                decimal: ',',
            };

            let { number } = e.target.dataset;
            const { decimals } = e.target.dataset;
            if (decimals) {
                options.decimalPlaces = decimals.length;
                number = `${number}.${decimals}`;
            }
            const count = new CountUp(e.target, number, options);
            count.start();
        }
    }, true);

    /* Set Darkmode */
    const storageGetDarkMode = JSON.parse(window.localStorage.getItem('darkMode'));
    const systemGetDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const { disableDarkModeGlobal } = window.wpUrls;

    if (disableDarkModeGlobal) {
        setDarkMode(false);
    } else if (storageGetDarkMode !== '' && storageGetDarkMode !== null) {
        setDarkMode(storageGetDarkMode);
    } else if (systemGetDarkMode !== '' && systemGetDarkMode !== null) {
        setDarkMode(systemGetDarkMode);
    }

    /* Herrlich Media Nav Init ++++++++++++++++++++++++++++++++++++++++++*/
    // hmNavInit();

    /* Btn Init ++++++++++++++++++++++++++++++++++++++++++*/
    window.btnInit();

    let scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    if (scrollbarWidth > 0) {
        setScrollbarWidth();
    }
    let endOfResize = null;

    window.addEventListener('resize', () => {
        clearTimeout(endOfResize);
        endOfResize = setTimeout(() => {
            setScrollbarWidth();
        }, 1000);
    });

    function setScrollbarWidth() {
        if (!navigator.maxTouchPoints > 0) {
            console.log('setScrollbarWidth: ', scrollbarWidth);
            scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.documentElement.style.setProperty(
                '--scrollbarWidth',
                `${scrollbarWidth}px`
            );
        }
    }

    /* Parallax BG Observer ++++++++++++++++++++++++++++++++++++++++++*/
    observeIntersection('.parallaxBackground .content', (e, intersected) => {
        if (intersected) {
            const currentContent = $(e.target);
            const currentIndex = $(e.target).attr('data-index');
            const currentImgWrapper = currentContent.parent().parent().parent().siblings('.backgroundImg__wrapper');
            const currentImg = currentImgWrapper.find(`[data-index=${currentIndex}]`);

            if (currentImg.length) {
                const currentImgDescription = currentImg.siblings('.imgDescription');

                currentImgWrapper.find('img').not(currentImg).removeClass('active');
                currentImgWrapper.find('.imgDescription').not(currentImgDescription).removeClass('active');
                currentImg.addClass('active');
                currentImgDescription.addClass('active');

                if (currentImg.hasClass('right')) {
                    currentImg.parent().parent().parent().addClass('right-align');
                } else {
                    currentImg.parent().parent().parent().removeClass('right-align');
                }
            }
        }
    }, false, 0, undefined, '-80% 0% -20% 0%');

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Event Listener ++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    $('.btn.scroll').on('click touch', () => {
        const theContent = $('#theContent');
        if (theContent.length) {
            dataStore.html.stop().animate({
                scrollTop: theContent.offset().top - 62
            }, 300);
        }
    });

    $('.header__darkMode').on('click touch', () => {
        if (dataStore.darkMode) {
            setDarkMode(false);
        } else {
            setDarkMode(true);
        }
    });

    $('.bigHeaderContent__scrollDown').on('click touch', () => {
        dataStore.html.stop().animate({
            scrollTop: $('#bigHeaderWrapper').offset().top + $('#bigHeaderWrapper').height() - 62
        }, 300);
    });

    /* Check for Heaer Video */
    const toggleMute = $('.header__settings__toggleMute');
    const bigHeaderVideo = document.getElementById('bigHeader__video');
    if ($('#bigHeaderWrapper.hasVideo').length && hasAudio(bigHeaderVideo)) {
        toggleMute.show();
        toggleMute.on('click touch', (e) => {
            $(e.currentTarget).toggleClass('isUnmuted');
            bigHeaderVideo.muted = !bigHeaderVideo.muted;
        });
    } else {
        toggleMute.remove();
    }

    $('.header__settings__search svg').on('click touch', (e) => {
        const current = $(e.currentTarget);
        const searchInputVal = current.siblings('form').find('[type=search]').val();

        if (searchInputVal === '') {
            current.parent().toggleClass('isActive');

            setTimeout(() => {
                if (current.parent().hasClass('isActive')) {
                    $('input[type=search]').focus();
                }
            }, 500);
        } else {
            current.siblings('form').submit();
        }
    });

    $('.copyLink').on('click touch', () => {
        copyTextToClipboard(window.location.href);
        /* eslint-disable-next-line */
        alert('Der Link wurde kopiert.');
    });

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Plugins +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
    lazyloadUpdate();

    /* plyr ++++++++++++++++++++++++++++++++++++++++++*/
    window.initPlyr();

    /* Ffancybox  ++++++++++++++++++++++++++++++++++++++++++*/
    Fancybox.bind(
        '.wp-block-gallery a[href$=jpg], .wp-block-gallery a[href$=png], .wp-block-gallery a[href$=webp]',
        {
            groupAll: true,
            animated: false,
            placeFocusBack: false
        }
    );

    Fancybox.bind(
        '.wp-block-image a[href$=jpg], .wp-block-image a[href$=png], .wp-block-image a[href$=webp]',
        {
            animated: false,
            placeFocusBack: false
        }
    );

    /* Bawue  ++++++++++++++++++++++++++++++++++++++++++*/
    if ($('.wp-block-heading.is-style-yelloLine').length) {
        const yellowLines = $('.wp-block-heading.is-style-yelloLine');

        yellowLines.each((i, v) => {
            const html = $(v).html();
            $(v).html(`<span>${html}</span>`);
        });

        // for (let i = 0; i < yellowLines.length; i++) {
        //     const element = yellowLines[i];
        //     console.log(element);
        // }
    }

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Scroll ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    document.addEventListener('scroll', () => {
        headerScrolled();
    });
}); /* ready end ++++++++++++++++++++++++++++++++++++++++++*/

$(window).resize(() => {
    dataStore.docWidth = $(document).width();
    dataStore.docHeight = $(document).height();
    dataStore.winHeight = $(window).height();

    $(window).trigger('scroll');

    clearTimeout(dataStore.endOfResize);
    dataStore.endOfResize = setTimeout(() => {
        afterResize();
        contentOffset();
    }, 250);
});

function afterResize() {
    // mobileSubmenu();
    // herrlichesMenu.destroy();
    // herrlichesMenu.init();
}

const headerScrolled = () => {
    dataStore.docScroll = $(window).scrollTop();

    if (dataStore.docScroll > 200) {
        dataStore.header.removeClass('notScrolled');
    } else {
        dataStore.header.addClass('notScrolled');
    }
};

const initImageSlider = (entry, isEntering) => {
    if (!isEntering) return;
    new Swiper(entry.target, {
        loop: false,
        lazy: {
            loadPrevNext: true,
            loadPrevNextAmount: 2,
        },
        slidesPerView: 'auto',
        spaceBetween: 16,
        centeredSlides: true,
        slideToClickedSlide: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        // breakpoints: {
        //     // when window width is >= 640px
        //     640: {
        //         centeredSlides: true,
        //     }
        // },
    });
};

$(window).on('load', () => {
    /* Loaded Class  ++++++++++++++++++++++++++++++++++++++++++*/
    dataStore.body.addClass('loaded');

    const imageSliders = document.body.querySelectorAll('.contentImageSlider');
    if (imageSliders.length) {
        observeIntersection('.contentImageSlider', initImageSlider, true);
    }

    /* Swiper  ++++++++++++++++++++++++++++++++++++++++++*/
    new Swiper('.bigHeader__slider', {
        loop: true,
        effect: 'fade',
        speed: 450,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
    });

    /* developed at ++++++++++++++++++++++++++++++++++++++++++*/
    devAt();

    /* set Btn name structur ++++++++++++++++++++++++++++++++++++++++++*/
    window.btnInit = () => {
        const btns = $('.btn');
        for (let btnsIndex = 0; btnsIndex < btns.length; btnsIndex++) {
            const btn = $(btns[btnsIndex]);
            const btnText = btn.text();

            btn.html(`<span data-text="${btnText}"><span>${btnText}</span></span>`);
        }
    };

    const btns = document
        .querySelectorAll('.btn:not(.arrow), .btn.accent:not(.noRandomColor), .btn.accent.bordered, .btn.blur, .header__button, .swiper-button-next:not(.noRandomColor), .swiper-button-prev:not(.noRandomColor)'); // eslint-disable-line
    btns.forEach((btn) => {
        btn.classList.add('randomColor');
    });
    // Random Color Function for wpUrls
    const { magazinColors } = window.wpUrls;
    const randomColor = document.querySelectorAll('.randomColor');
    if (magazinColors?.length && randomColor) {
        randomColor.forEach((el) => {
            el.style.background = magazinColors[Math.floor(Math.random() * magazinColors.length)].color;
        });
    }
});

// Lazyload Update
const lazyloadUpdate = function lazyloadUpdate() {
    if (dataStore.lazyLoadInstance !== '') {
        dataStore.lazyLoadInstance.update();
    }
};

/* Webp Support Detection  ++++++++++++++++++++++++++++++++++++++++++*/
async function supportsWebp() {
    if (!window.self.createImageBitmap) return false;

    const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
    const blob = await fetch(webpData).then((r) => r.blob());
    return createImageBitmap(blob).then(() => true, () => false);
}

// Add Webp to Background Imgaes when supported
(async() => {
    if (await supportsWebp()) {
        dataStore.body.addClass('webp');
        const lazyBg = document.querySelectorAll('[data-bg]');
        lazyBg.forEach((element) => {
            let { bg } = element.dataset;
            // eslint-disable-next-line prefer-regex-literals
            if (bg.match(new RegExp('.*(.jpg|.jpeg|.png)')) && !bg.includes('.webp') && !bg.includes('cdninstagram')) {
                bg = `${bg}.webp`;
                element.setAttribute('data-bg', bg);
            }
        });
    }

    /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
    dataStore.lazyLoadInstance = new LazyLoad({
        elements_selector: '.lazy'
    });
    lazyloadUpdate();
})();

// init plyr
window.initPlyr = () => {
    setTimeout(() => {
        Array.from(document.querySelectorAll('.plyr:not(.plyr--video):not(.headerPlyr)')).map((p) => new Plyr(p, {
            controls:
            ['play-large', 'play', 'progress', 'current-time', 'mute',
                'volume', 'captions', 'pip', 'airplay', 'fullscreen']
        }));

        const headerPlayer = new Plyr('.headerPlyr:not(.plyr--video)', {
            youtube: {
                noCookie: false,
                rel: 0,
                showinfo: 0,
                iv_load_policy: 3,
                modestbranding: 1,
                autoplay: 1,
                playsinline: 1,
                disablekb: 1,
                hl: 0,
                widget_referrer: 0,
            },
            vimeo: {
                muted: 1,
                autoplay: 1,
                playsinline: 1,
                loop: 1,
            },
            autoplay: true,
            autopause: false,
            muted: true,
            loop: { active: true },
            controls: [],
        });

        headerPlayer.on('ready', () => headerPlayer.play());
    }, 1000);
};

const setDarkMode = (darkMode) => {
    if (!darkMode) {
        dataStore.body.removeClass('night').addClass('day');
        window.localStorage.setItem('darkMode', false);
        dataStore.darkMode = false;
    } else {
        dataStore.body.addClass('night').removeClass('day');
        window.localStorage.setItem('darkMode', true);
        dataStore.darkMode = true;
    }
};

window.btnInit = () => {
    const btns = $('.btn');
    for (let btnsIndex = 0; btnsIndex < btns.length; btnsIndex++) {
        const btn = $(btns[btnsIndex]);
        const btnText = btn.text();

        btn.html(`<span data-text="${btnText}"><span>${btnText}</span></span>`);
    }
};

function hasAudio(video) {
    return video.mozHasAudio
    || Boolean(video.webkitAudioDecodedByteCount)
    || Boolean(video.audioTracks && video.audioTracks.length);
}

function copyTextToClipboard(text) {
    const textArea = document.createElement('textarea');

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if the element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //

    // Place in the top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of the white box if rendered for any reason.
    textArea.style.background = 'transparent';

    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        /* eslint-disable-next-line */
        console.log(`Copying text command was ${msg}`);
    } catch (err) {
        /* eslint-disable-next-line */
        console.log('Oops, unable to copy');
    }

    document.body.removeChild(textArea);
}

const contentOffset = () => {
    if (document.querySelector('#theContent .col-lg-8')) {
        const padding = 32;
        const contentArea = document.querySelector('#theContent .col-lg-8');
        const contentWidth = document.querySelector('.container');
        document.body.style.setProperty(
            '--fullwidthcontentwidth',
            `${document.body.clientWidth}px`
        );

        document.body.style.setProperty(
            '--fullwidthcontentoffset',
            `${((contentArea.clientWidth - parseInt(padding, 10)) - document.body.clientWidth) / 2}px`
        );
        document.body.style.setProperty(
            '--contentoffset',
            `${
                (contentArea.clientWidth / 4) * -1
            }px`
        );
        document.body.style.setProperty(
            '--contentwidth',
            `${contentWidth.clientWidth - (parseInt(padding, 10))}px`
        );
        document.body.style.setProperty(
            '--columnOffset',
            `${
                ((contentArea.clientWidth - document.body.clientWidth)
                / 2) * -1
            }px`
        );
        document.body.style.setProperty(
            '--teaserImageOffset',
            `${
                ((document.body.clientWidth
                    - (contentWidth.clientWidth - parseInt(padding, 10)) + 56)
                    / 2)
                * -1
            }px`
        );
    }
};
